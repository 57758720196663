import secureLs from '@/libs/secureLs'

export default {
  namespaced: true,
  state: {
    userData: secureLs.getItem('userData') ? secureLs.getItem('userData') : {},
  },
  mutations: {
    UPDATE_USER_DATA(state, val) {
      state.userData = val
    },
  },
  actions: {},
}

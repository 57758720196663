import { getField, updateField } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { newAxiosIns } from '@/libs/axios'

export default {
  components: {
    ToastificationContent,
  },
  namespaced: true,
  state: {
    partnerVerified: false,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    UPDATE_PARTNER_VERIFIED(state, data) {
      state.partnerVerified = data
    },
  },
  actions: {
    async init({ dispatch }) {
      dispatch('getPartnerVerified')
    },
    async getPartnerVerified({ commit, rootState }) {
      const partnerId = rootState.auth.userData.partner_detail.id
      try {
        const response = await newAxiosIns.get(`/auth/api/v1/partner/request-verification-ktp/by-partner/${partnerId}`)
        commit('UPDATE_PARTNER_VERIFIED', response.data.data.partner_is_verified)
      } catch (err) {
        console.error(err)
      }
    },
  },
}

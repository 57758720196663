export default [
  {
    path: '/form-order',
    name: 'form-order',
    component: () => import('@/views/pages/komform/Komform.vue'),
    meta: {
      resource: 'Form Order',
      action: 'manage',
    },
  },
]

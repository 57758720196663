import useJwt from '@/auth/jwt/useJwt'
import secureCookies from '@/libs/cookies'
import secureLs from '@/libs/secureLs'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    secureLs.getItem('userData')
    && secureCookies.getItem(useJwt.jwtConfig.storageTokenKeyName)
  )
}

export const removeLocalStorageLogout = () => {
  secureLs.removeItem('userData')
  secureCookies.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem('vuex-store')
}

export const getUserData = () => secureLs.getItem('userData')

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole, status) => {
  const userData = secureLs.getItem('userData')
  if (userRole === 'PARTNER') {
    if (userData.is_kompack === 1 && userData.is_komship === 0) return '/gudangku'
    if (userData.is_komchat === 1 && userData.is_komship === 0) return '/broadcast'
    return '/'
  }
  // if (userRole === 'PARTNER' && status === 0) return '/'
  // if (userRole === 'PARTNER' && status === 1) return '/'
  // if (userRole === 'PARTNER' && status === 0) return '/komtim'
  if (userRole === 'TALENT GLOBAL') return userData.path
  return { name: 'auth-login' }
}

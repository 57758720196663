export default [
  {
    path: '/iklan',
    name: 'marketing',
    component: () => import('@/views/pages/komads/List.vue'),
    meta: {
      resource: 'Marketing',
      action: 'manage',
    },
  },
  {
    path: '/iklan/balance-history',
    name: 'marketing',
    component: () => import('@/views/pages/komads/HistoryBalance.vue'),
    meta: {
      resource: 'Marketing',
      action: 'manage',
      navActiveLink: 'marketing',
    },
  },
]

export default {
  namespaced: true,
  state: {
    viewList: 'grid',
  },
  mutations: {
    UPDATE_VIEW_LIST(state, value) {
      state.viewList = value
    },
  },
}

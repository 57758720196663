/* eslint-disable no-param-reassign */
import moment from 'moment'
import { axiosKomship } from '../helpers'

export default {
  namespaced: true,

  state: () => ({
    loading: true,
    limitOrder: null,
    profile: null,
    variantsProduct: null,
    saldo: null,
    shipmentIsActive: false,
    dataSheets: [],
    lastUpdated: null,
    shipmentIsFlat: false,
    isKompackUser: false,

    // Data Source
    source: {
      address_source: [],
      custom_label_source: [],
      payment_source: [],
      product_source: [],
      shipment_source: [],
      tracking_sales_source: [],
      variant_source: [],
      district_source: [],
      product_weight: [],
    },
    kompackSource: null,
  }),

  getters: {},

  mutations: {
    PROFILE_UPDATE(state, args) {
      state.isKompackUser = args.is_kompack === 1
      state.profile = args
    },
    ADDRESS_SOURCE_UPDATE(state, args) {
      state.source.address_source = args
    },
    CUSTOM_LABEL_SOURCE_UPDATE(state, args) {
      state.source.custom_label_source = args
    },
    PAYMENT_SOURCE_UPDATE(state, args) {
      state.source.payment_source = args
    },
    PRODUCT_SOURCE_UPDATE(state, args) {
      state.source.product_source = args
    },
    VARIANT_SOURCE_UPDATE(state, args) {
      const data = []
      const filter = args.filter(item => item.variant !== '-')
      for (let x = 0; x < filter.length; x += 1) {
        data.push(...filter[x].variant)
      }
      state.source.variant_source = data
      state.variantsProduct = args
    },
    PRODUCT_WEIGHT_UPDATE(state, args) {
      state.source.product_weight = args
    },
    TRACKING_SALES_SOURCE_UPDATE(state, args) {
      state.source.tracking_sales_source = args
    },
    DISTRICT_SOURCE_UPDATE(state, args) {
      state.source.district_source = args
    },
    SHIPMENT_SOURCE_UPDATE(state, args) {
      state.source.shipment_source = args
    },
    SALDO_UPDATE(state, args) {
      state.saldo = args
    },
    SHIPMENT_ACTIVE_UPDATE(state, args) {
      const find = args.list_shipments.find(item => item.is_active === 1)
      state.shipmentIsActive = find !== undefined
      const findFlat = args.list_shipments.find(item => item.is_flat === 1)
      state.shipmentIsFlat = findFlat !== undefined
    },
    DATA_SHEETS_UPDATE(state, args) {
      state.dataSheets = []
      if (args.length === 0) {
        const arrays = Array.from(Array(200))
        arrays.forEach(() => {
          state.dataSheets.push({
            order_date: '',
            custom_label: '',
            tracking_sales: '',
            address_from: '',
            customer_name: '',
            customer_phone_number: '',
            zip_code: '',
            district_name: '',
            customer_address: '',
            product: '',
            variant: '',
            order_notes: '',
            qty: '',
            payment_method: '',
            shipment: '',
            grandtotal: '',
          })
        })
      } else {
        state.dataSheets = args.map(item => ({
          order_date: item.order_date ? item.order_date : '',
          custom_label: item.custom_label,
          tracking_sales: item.tracking_sales,
          address_from: item.address_from,
          customer_name: item.customer_name,
          customer_phone_number: item.customer_phone_number,
          zip_code: item.zip_code,
          district_name: item.district_name,
          customer_address: item.customer_address,
          product: item.product,
          variant: item.variant,
          order_notes: item.order_notes,
          qty: item.qty,
          payment_method: item.payment_method,
          shipment: item.shipment,
          grandtotal: String(item.grandtotal).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ''),
        }))
      }
      state.dataSheets.forEach(item => delete item.row)
      if (!state.profile.partner_is_custom_label) {
        state.dataSheets.forEach(item => delete item.custom_label)
      }
      if (!state.profile.partner_is_tracking_sales) {
        state.dataSheets.forEach(item => delete item.tracking_sales)
      }
      if (!state.profile.partner_is_order_notes) {
        state.dataSheets.forEach(item => delete item.order_notes)
      }
    },
    LAST_UPDATED(state, args) {
      const { link } = args.data
      const date = moment(link.updated_at).format('YYYY-MM-DD')
      if (date === moment().format('YYYY-MM-DD')) {
        const time = moment(link.updated_at).format('HH:mm')
        state.lastUpdated = `pada pukul ${time}, hari ini`
      } else {
        const monthName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
        const day = moment(link.updated_at).format('DD')
        const month = moment(link.updated_at).format('M')
        const year = moment(link.updated_at).format('YYYY')
        const time = moment(link.updated_at).format('HH.mm')
        state.lastUpdated = `pada pukul ${time}, ${day} ${monthName[month - 1]} ${year}`
      }
    },
    LIMIT_ORDER_UPDATE(state, args) {
      state.limitOrder = args
    },
    ADD_SOURCE_KOMPACK(state, args) {
      args.addresses.forEach(item => {
        item.product.forEach(productItem => {
          if (!state.source.product_source.includes(productItem)) {
            state.source.product_source.push(productItem)
          }
        })
        state.source.address_source.push(`[Kompack] ${item.warehouse}`)
      })
      state.kompackSource = args.addresses
      state.source.product_weight = state.source.product_weight.concat(args.product_weight)
      state.variantsProduct = state.variantsProduct.concat(args.variant)
    },
  },

  actions: {
    limitOrderUser({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .get(
            '/v1/user/limit-order',
          )
          .then(response => {
            commit('LIMIT_ORDER_UPDATE', response)
            resolve(response)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },

    shipmentCheck({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .get(
            '/v2/partner/shipment/not-active',
          )
          .then(response => {
            commit('SHIPMENT_ACTIVE_UPDATE', response.data.data)
            resolve(response)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },

    saldoCheck({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .get(
            '/v1/dashboard/partner/balanceSummary',
          )
          .then(response => {
            commit('SALDO_UPDATE', response.data.data.balance)
            resolve(response)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },

    profileFetch({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .post(
            '/v1/my-profile',
          )
          .then(response => {
            const { data } = response.data
            commit('PROFILE_UPDATE', data)
            resolve(response)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },

    fetchDraftOrder({ dispatch, commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .get(
            '/v1/order/sheet/data',
          )
          .then(response => {
            commit('DATA_SHEETS_UPDATE', response.data.data)
            dispatch('lastUpdatedSheets')
            resolve(response)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },

    fetchDropdownSheets({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .get(
            '/v2/order/sheet/drop-down',
          )
          .then(response => {
            const { data } = response.data
            resolve(data)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },

    lastUpdatedSheets({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .get(
            '/v1/order/sheet/last-update',
          )
          .then(response => {
            commit('LAST_UPDATED', response)
            resolve(response)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },

    fetchDistrict({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .get(
            `/v1/order/sheet/list-district?zip_code=${payload}`,
          )
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },

    calculate({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .get(
            `v1/order/sheet/calculate${payload}`,
          )
          .then(response => {
            const { data } = response.data
            resolve(data)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },

    submit({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .post(
            '/v2/order/sheet/save-submit',
            payload,
          )
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },

    fetchKompackDropdown({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKomship()
          .get(
            '/v1/order/sheet/kompack-drop-down',
          )
          .then(response => {
            const { data } = response.data
            resolve(data)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },
  },

}

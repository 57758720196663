import { newAxiosIns } from '@/libs/axios'

export function apiGetSubsPlan() {
  return newAxiosIns.get('komship/api/v1/whatsapp/subscription/plan')
}

export function apiSubs(id) {
  const params = {
    plan_id: id,
  }
  return newAxiosIns.post('komship/api/v1/whatsapp/subscribe', params)
}

export function apiConnectFb(phone_number_id, waba_id, code) {
  const params = {
    phone_number_id,
    waba_id,
    code,
  }
  return newAxiosIns.post('komchat-api/api/v1/channel/connect', params)
}

export function apiListDataNumber() {
  return newAxiosIns.get('komchat-api/api/v1/channel/list')
}

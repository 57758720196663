/* eslint-disable consistent-return */
import isEmpty from 'lodash/isEmpty'
import encryption from './encryption'

const secureLs = {
  getItem: name => {
    const data = localStorage.getItem(encryption.encryptWithMD5(name))
    let state
    if (!isEmpty(data)) {
      const result = encryption.decryptWithAES(data)
      try {
        state = JSON.parse(result)
      } catch (error) {
        state = result
      }

      return state
    }
  },
  setItem: (name, data) => localStorage.setItem(
    encryption.encryptWithMD5(name),
    encryption.encryptWithAES(data),
  ),
  removeItem: name => localStorage.removeItem(encryption.encryptWithMD5(name)),
  clearItem: name => localStorage.clear(),
}

export default secureLs

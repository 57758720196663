import { getField, updateField } from 'vuex-map-fields'
import {
  axiosKompack,
} from '@/store/helpers'

export default {
  namespaced: true,
  state: {
    selecOptData: {
      building: [],
      ownership: [],
    },
  },
  getters: {
    getField,
    getselecOptData(state) {
      return state.selecOptData
    },
  },
  mutations: {
    updateField,
    UPDATE_ِِSELECT_OPTIONDATA(state, optdata) {
      state.selecOptData = {
        ...state.selecOptData,
        ...optdata,
      }
    },
  },
  actions: {
    async init({ dispatch }) {
      dispatch('getSelectOptDataAPI')
    },
    async getSelectOptDataAPI({ commit, rootState }) {
      try {
        const { data } = await axiosKompack().get('/kompack/select-option')
        const buildingType = [
          {
            value: null,
            text: 'Pilih jenis bangunan',
          },
        ]
        const ownerShip = [
          {
            value: null,
            text: 'Pilih jenis kepemilikan',
          },
        ]
        data.data.ownership.forEach(x => {
          ownerShip.push({
            value: x.id,
            text: x.name,
          })
        })
        data.data.building.forEach(x => {
          buildingType.push({
            value: x.id,
            text: x.name,
          })
        })
        commit('UPDATE_ِِSELECT_OPTIONDATA', {
          building: buildingType,
          ownership: ownerShip,
        })
      } catch (e) {
        console.error(e)
      }
    },
  },
}

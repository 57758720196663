import Cookies from 'js-cookie'

import isEmpty from 'lodash/isEmpty'
import encryption from './encryption'

const secureCookies = {
  getItem: key => {
    const data = Cookies.get(encryption.encryptWithMD5(key))

    let state
    if (!isEmpty(data)) {
      const result = encryption.decryptWithAES(data)
      try {
        state = JSON.parse(result)
      } catch (error) {
        state = result
      }

      return state
    }

    return undefined
  },
  setItem: (key, value, options) => Cookies.set(
    encryption.encryptWithMD5(key),
    encryption.encryptWithAES(value),
    options,
  ),
  removeItem: key => {
    Cookies.remove(encryption.encryptWithMD5(key))
  },
}

export default secureCookies

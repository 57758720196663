export default [
  {
    path: '/payment-setting',
    name: 'payment-setting',
    component: () => import('@/views/pages/komship/setting-kompship/Payment/payment-setting'),
    meta: {
      name: 'payment-setting',
      resource: 'Setting Komship',
      breadcrumb: [
        {
          text: 'Pengaturan',
        },
        {
          text: 'payment-setting',
          active: true,
        },
      ],
    },
  },
]

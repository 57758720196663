import { getField, updateField } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { axiosKomship } from '@/store/helpers'

export default {
  components: {
    ToastificationContent,
  },
  namespaced: true,
  state: {
    expedition: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    UPDATE_EXPEDITION(state, data) {
      state.expedition = data
    },
  },
  actions: {
    async init({ dispatch }) {
      dispatch('getUpdateExpedition')
    },
    async getUpdateExpedition({ commit, rootState }) {
      try {
        const response = await axiosKomship().get(
          'v2/shipments',
        )
        commit('UPDATE_EXPEDITION', response.data.data)
      } catch (err) {
        console.error(err)
      }
    },
  },
}

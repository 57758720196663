const pageName = { singular: 'Customer', plural: 'Customer' }
const pageNames = { singular: 'Broadcast', plural: 'Broadcast' }

export default [
  {
    path: '/broadcast',
    name: 'broadcast',
    component: () => import('@/views/pages/komship/customer/Broadcast.vue'),
    meta: {
      name: pageNames,
      routeDetail: 'broadcast',
      navActiveLink: 'broadcast',
      resource: 'Broadcast',
      action: 'manage',
      breadcrumb: [
        {
          text: pageNames.plural,
          active: true,
        },
      ],
    },
  },
  {
    path: '/info-customer',
    name: 'info-customer',
    component: () => import('@/views/pages/komship/customer/InfoCustomer.vue'),
    meta: {
      name: pageName,
      routeDetail: 'detail-customer',
      navActiveLink: 'info-customer',
      resource: 'Info Customer',
      action: 'manage',
      breadcrumb: [
        {
          text: pageName.plural,
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-customer',
    name: 'data-customer',
    component: () => import('@/views/pages/komship/customer/DataCustomer.vue'),
    meta: {
      name: pageName,
      navActiveLink: 'data-customer',
      resource: 'Data Customer',
      action: 'manage',
      breadcrumb: [
        {
          text: pageName.plural,
          active: true,
        },
      ],
    },
  },
  {
    path: '/grup-contact',
    name: 'grup-contact',
    component: () => import('@/views/pages/komship/customer/GrupKontak.vue'),
    meta: {
      name: pageNames,
      navActiveLink: 'grup-contact',
      resource: 'Group Contact',
      action: 'manage',
      breadcrumb: [
        {
          text: pageNames.plural,
          active: true,
        },
      ],
    },
  },
  {
    path: '/grup-contact/list-contact',
    name: '/grup-contact/list-contact',
    component: () => import('@/views/pages/komship/customer/DaftarKontak.vue'),
    meta: {
      name: pageNames,
      navActiveLink: 'grup-contact',
      resource: 'Group Contact',
      action: 'manage',
      breadcrumb: [
        {
          text: pageNames.plural,
          active: true,
        },
        {
          text: pageNames.plural,
        },
      ],
    },
  },
  {
    path: '/grup-contact/create-contact',
    name: 'create-contact',
    component: () => import('@/views/pages/komship/customer/CreateNewContact.vue'),
    meta: {
      name: pageNames,
      navActiveLink: 'grup-contact',
      resource: 'Group Contact',
      action: 'manage',
      breadcrumb: [
        {
          text: pageNames.plural,
          active: true,
        },
        {
          text: pageNames.plural,
        },
      ],
    },
  },
  {
    path: '/import-from-komship',
    name: 'import-from-komship',
    component: () => import('@/views/pages/komship/customer/InputFromKomship.vue'),
    meta: {
      name: pageNames,
      navActiveLink: 'grup-contact',
      resource: 'Group Contact',
      action: 'manage',
      breadcrumb: [
        {
          text: pageNames.plural,
          active: true,
        },
      ],
    },
  },
  {
    path: '/group-contact/detail-customer/:customer_id',
    name: 'detail-customer-contact',
    component: () => import('@/views/pages/komship/customer/DetailCustomer.vue'),
    meta: {
      name: pageNames,
      navActiveLink: 'grup-contact',
      routeDetailOrder: 'detail-order',
      resource: 'Group Contact',
      action: 'manage',
      breadcrumb: [
        {
          text: pageNames.plural,
        },
        {
          text: 'Detail Customer Kontak',
          active: true,
        },
      ],
    },
  },
  {
    path: '/info-customer/detail-customer/:customer_id',
    name: 'detail-customer',
    component: () => import('@/views/pages/komship/customer/DetailCustomer.vue'),
    meta: {
      name: pageName,
      navActiveLink: 'info-customer',
      routeDetailOrder: 'detail-order',
      resource: 'Info Customer',
      action: 'manage',
      breadcrumb: [
        {
          text: pageName.plural,
        },
        {
          text: 'Detail Customer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/info-customer/detail-customer/detail-order/:order_id',
    name: 'detail-order',
    component: () => import('@/views/pages/komship/customer/DetailOrder.vue'),
    meta: {
      name: 'Detail Order',
      navActiveLink: 'info-customer',
      resource: 'Info Customer',
      action: 'manage',
      breadcrumb: [
        {
          text: pageName.plural,
        },
        {
          text: 'Detail order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/broadcast/send-message',
    name: 'broadcast-send-message',
    component: () => import('@/views/pages/komship/customer/SendMessage.vue'),
    meta: {
      name: pageNames,
      navActiveLink: 'send-message',
      resource: 'Broadcast',
      action: 'manage',
      breadcrumb: [
        {
          text: pageNames.plural,
          active: true,
        },
        {
          text: 'Send Message',
          active: true,
        },
      ],
    },
  },
]

export default [
  {
    path: '/voucher',
    name: 'voucher',
    component: () => import('@/views/pages/voucher/List.vue'),
    meta: {
      name: 'voucher',
      resource: 'Setting Komship',
    },
  },
]
